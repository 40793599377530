<template>
  <div class="warehouse">
    <breadcrumb :items="breadCrumbs" :key="category.categoryId" />
    <v-container fluid>
      <v-row
        ><v-col cols="12" md="6">
          <ebsn-meta
            tag="h1"
            path="category_info.TITLE"
            :target="category"
            :default="category.name"
            class="font-weight-bold"
          />

          <ebsn-meta
            path="category_info.DESCRIPTION"
            :target="category"
            :default="category.name"
            class="description"
          />
        </v-col>
        <v-col md="6">
          <ebsn-img
            :target="category"
            path="category_info.THUMB"
            defaultValue="/img_layout/img-regione.png"
          />
        </v-col>
      </v-row>

      <h2 color="primary lighten-1" class="py-3">
        {{ $t("storeLocator.discoverRegion", { name: category.name }) }}
      </h2>

      <v-row class="province">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
          v-for="province in category.children"
          :key="province.categoryId"
        >
          <v-card
            class="btn-province"
            :to="{
              name: 'WarehouseProvince',
              params: { province: province.slug }
            }"
          >
            <ebsn-img
              tag="v-img"
              height="140"
              :target="province"
              :alt="province.name"
              path="category_info.THUMB"
              defaultValue="/img_layout/img-provincia.png"
            />
            <v-card-title>{{ province.name }} </v-card-title>
            <v-card-text
              v-html="
                $t('storeLocator.discoverAllStores', {
                  province: province.name
                })
              "
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :to="{
                  name: 'WarehouseProvince',
                  params: {
                    province: province.slug,
                    regionSlug: category.slug,
                    regionName: category.name
                  }
                }"
                fab
                color="primary lighten-1"
                small
                depressed
              >
                <v-icon small dark>$chevronRight</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import category from "~/mixins/category.js";
import categoryTitle from "@/mixins/categoryTitle.js";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
export default {
  components: { Breadcrumb },
  name: "StoreLocator",
  mixins: [category, categoryTitle],
  computed: {
    breadCrumbs() {
      let breadCrumbs = [
        { to: { name: "Home" }, text: "Homepage", exact: true },
        { to: { name: "WarehouseLocator" }, text: "Punti vendita", exact: true }
      ];
      if (this.category) {
        breadCrumbs.push({
          to: {
            name: "WarehouseRegion"
          },
          text: this.category.name,
          exact: true
        });
      }
      return breadCrumbs;
    }
  }
};
</script>
