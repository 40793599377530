var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse"},[_c('breadcrumb',{key:_vm.category.categoryId,attrs:{"items":_vm.breadCrumbs}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ebsn-meta',{staticClass:"font-weight-bold",attrs:{"tag":"h1","path":"category_info.TITLE","target":_vm.category,"default":_vm.category.name}}),_c('ebsn-meta',{staticClass:"description",attrs:{"path":"category_info.DESCRIPTION","target":_vm.category,"default":_vm.category.name}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('ebsn-img',{attrs:{"target":_vm.category,"path":"category_info.THUMB","defaultValue":"/img_layout/img-regione.png"}})],1)],1),_c('h2',{staticClass:"py-3",attrs:{"color":"primary lighten-1"}},[_vm._v(" "+_vm._s(_vm.$t("storeLocator.discoverRegion", { name: _vm.category.name }))+" ")]),_c('v-row',{staticClass:"province"},_vm._l((_vm.category.children),function(province){return _c('v-col',{key:province.categoryId,attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-card',{staticClass:"btn-province",attrs:{"to":{
              name: 'WarehouseProvince',
              params: { province: province.slug }
            }}},[_c('ebsn-img',{attrs:{"tag":"v-img","height":"140","target":province,"alt":province.name,"path":"category_info.THUMB","defaultValue":"/img_layout/img-provincia.png"}}),_c('v-card-title',[_vm._v(_vm._s(province.name)+" ")]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(
                _vm.$t('storeLocator.discoverAllStores', {
                  province: province.name
                })
              )}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"to":{
                  name: 'WarehouseProvince',
                  params: {
                    province: province.slug,
                    regionSlug: _vm.category.slug,
                    regionName: _vm.category.name
                  }
                },"fab":"","color":"primary lighten-1","small":"","depressed":""}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("$chevronRight")])],1)],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }